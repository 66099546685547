<template>
  <Navbar />
  <Banner />
  <SliderCampaign />
  <div class="container category-wrapper row">
     <Category />
  </div>
  <RegisterLembagaComponent />
  <program-pilihan/>
  <tag-populer />
  <new-campaign/>
  <div class="container container__about-footer">
      <div class="footer-sosmed">
      <a href="https://www.facebook.com/amazingsedekah" target="_blank"><i class="fab fa-facebook-square"></i></a>
      <a href="https://www.instagram.com/amazingsedekah" target="_blank"><i class="fab fa-instagram-square"></i></a>
      <a href="https://blog.amazingsedekah.com" target="_blank"><i class="fas fa-blog"></i></a>
    </div>
    <h3 class="title__wrapper">Tentang Amazingsedekah.com</h3>
    <p>Amazingsedekah.com merupakan platform penggalangan donasi secara online yang dikelola oleh Yayasan Amazing Sedekah Indonesia untuk menghimpun dana donasi infak, sedekah, zakat & wakaf melalui program-program asasi yang dibutuhkan masyarakat.</p>
    <h3 class="title__wrapper">Disclaimer</h3>
    <p>Donasi melalui Yayasan Amazing Sedekah Indonesia bukaan bersumber dan bukan bertujuan untuk
    kegiatan pencucian uang, terorisme atau lainnya yang mengarah kepada tindakan kejahatan.
    Donasi yang disalurkan telah dipotong biaya yang dibebankan oleh pihak ketiga dalam operasional
    website, seperti biaya payment gateway, notifikasi, serta pengelolaan dan pengembangan sistem.</p>
    
    <!--<p>Alamat Kantor di: Jl. H. Montong No. 48 RT 05 RW 02 Ciganjur Jakarta Selatan 12630</p>-->
  </div>
  <Menu />
</template>

<script>
import { defineComponent } from 'vue'

import RegisterLembagaComponent from '../components/Home/RegisterLembagaComponent.vue'
import Navbar from '../components/Home/Navbar.vue'
import Banner from '../components/Home/Banner.vue'
import SliderCampaign from '../components/Home/SliderCampaign.vue'
import ProgramPilihan from '../components/Home/ProgramPilihan.vue'
import TagPopuler from '../components/Home/TagPopuler.vue'
import Category from '../components/Home/Category.vue'
import Menu from '../components/Home/Menu.vue'
import NewCampaign from '../components/Home/NewCampaign.vue'

// export default defineComponent({
//   components: { Banner, SliderCampaign, Category, Menu, Navbar, ProgramPilihan, TagPopuler, NewCampaign }
// })
export default defineComponent({
  components: { Banner, SliderCampaign, Category, Menu, Navbar, RegisterLembagaComponent, ProgramPilihan, TagPopuler, NewCampaign }
})
</script>
