<template>
  <div class="container" style="margin-top:-132px !important; positioin:relative;">
    <div class="card card__skeleton-slider">
      <Skeleton
        width="100"
        height="298"
        style="border-top-left-radius:calc(.25rem - 1px); border-top-right-radius:calc(.25rem - 1px);"
      />
      <div class="card-body">
        <Skeleton width="70%" height="22" />
        <Skeleton width="100" height="32" style="margin-top:5px;"/>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'

export default {
  components: { Skeleton }
}
</script>
