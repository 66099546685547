<template>
  <LoaderBanner v-if="loading" />
  <div v-else class="container d-flex justify-content-center campaign__card sliderWrapper mb-5">
    <swiper class="swiper" :autoplay="swiperOption.autoplay" :pagination="swiperOption.pagination">
      <swiper-slide v-for="(banner, index) in campaigns" class="swiper-slide" :key="index">
        <a :href="banner.bannerUrl" class="card card__campaign-wrapper">
          <div class="image__banner-gallery">
            <div class="image__banner-cover">
              <img
                :src="banner.bannerImage"
                class="card-img-top image__campaign"
                alt="Banner Image"
              />
            </div>
          </div>
          <!--<div class="card-body">-->
          <!--  <h5 class="card-title title__campaign">-->
          <!--    {{ banner.bannerTitle }}-->
          <!--  </h5>-->
          <!--  <p class="card-text desc__campaign">-->
          <!--    {{ banner.bannerDescription }}-->
          <!--  </p>-->
          <!--</div>-->
        </a>
      </swiper-slide>
      
    </swiper>
    <div class="swiper-pagination" />
  </div>
</template>

<script>
import { imageCdn } from '@/_helpers'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
import LoaderBanner from '../Loader/LoaderBanner.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    LoaderBanner
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2000
        }
      },
      campaigns: [],
      firstSwiper: null,
      loading: true
    }
  },
  mounted() {
    this.getBannerCampaign()
  },
  methods: {
    getBannerCampaign() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}banners?order=banner_position&ascending=1&is_active=1`)
        .then(response => {
          const datas = response.data.data
          datas.forEach(value => {
            const dataObj = {
              bannerUuid: value.banner_id,
              bannerTitle: value.banner_title,
              bannerDescription: value.banner_description,
              bannerImage: imageCdn(value.banner_image_url, 'banner'),
              bannerUrl: value.banner_url
            }

            this.campaigns.push(dataObj)
            this.loading = false
          })
        })
    },
    setFirstSwiper(swiper) {
      this.firstSwiper = swiper
    }
  }
}
</script>
