<template>
  <div class="container tag__populer-wrapper" v-if="tags.length > 0">
    <div class="d-flex">
      <swiper class="swiper swiper-container_tag" :slidesPerView="6" :spaceBetween="6">
        <swiper-slide v-for="(tag, index) in tags" :key="index" class="swiper-slide" style="width:fit-content;">
          <div class="tag__populer" @click="handleClickTag">#{{ tag.tagName }}</div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      tags: []
    }
  },
  mounted() {
    this.getTags()
  },
  methods: {
    getTags() {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)
      params.append('used', 1)
      this.$http
        .get(`${process.env.VUE_APP_API_URL}tags?${params.toString()}`)
        .then(({ data }) => {
          const datas = data.data
          if (datas.length > 0) {
            datas.forEach(value => {
              const objData = {
                tagId: value.tag_id,
                tagName: value.tag_name
              }

              this.tags.push(objData)
            })
          }
        })
        .catch(() => {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Terjadi kesalahan'
          })
        })
    },
    handleClickTag(e) {
      const targetElement = e.target
      targetElement.style.backgroundColor = '#F28F1D'
      targetElement.style.color = '#FFF'
      const valueHTML = targetElement.innerHTML
      const splitValue = valueHTML.split('#')
      const splitValueTwo = splitValue[1].split(' ')
      this.$router.push({
        path: '/search',
        query: { hashtag: splitValueTwo[0] }
      })
    }
  }
}
</script>
