<template>
    <div class="container container__new-campaign">
        <h3 class="title__wrapper">Semua Program</h3>

        <router-link v-for="(campaign,index) in campaigns" :key="index" :to="`/${campaign.wakafUrl}`" class="list__category-wrapper list__new-wrapper">
            <div class="information__category">
                <span class="title__category">
                     {{campaign.wakafName}} 
                </span>
                <div class="d-flex align-items-center">
                    <span class="company__name">{{campaign.company.companyName}}</span>
                </div>
                <div class="progress progress__category">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{width: `${campaign.wakafTargetPercent}%`}"
                />
                </div>
                <span class="title__donation">Terkumpul</span>
                <h5 class="value__donation">Rp. {{$formatPrice(campaign.totalTerhimpun)}}</h5>
                <button v-if="campaign.wakafTargetValue !== ''" type="button" class="btn btn-danger btn-sm btn btn__end-category btn__color-red">
                    <i class="icon-date-white" />
                    <span class="value__end-date">
                        <b>{{campaign.wakafTargetValue}} </b>
                    </span>
                </button>
            </div>
            <div class="tumbnail__category-gallery">
                <img :src="campaign.wakafImage" alt="Cover Image" class="category__cover-image" />
            </div>
        </router-link>

        <div class="link__show-campaign">
            <router-link to="/campaigns" class="text__show-link">
                Tampilkan lebih banyak
            </router-link>
        </div>
    </div>
</template>

<script>
import { imageCdn } from '@/_helpers'
// import swal from 'sweetalert2'
export default {
    data() {
        return {
            imageOne: require('../../assets/img/image_popular_1.jpeg'),
            imageTwo: require('../../assets/img/image_popular_2.jpeg'),
            imageThree: require('../../assets/img/image_popular_3.jpeg'),
            campaigns: []
        }
    },
    mounted(){
        this.getNewCampaigns()
    },
    methods: {
        getNewCampaigns() {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            params.append('order','created_at')
            params.append('descanding',1)
            params.append('limit',5)
            params.append('need_cat',1)
            params.append('ignore_description',1)
            
            this.$http.get(`${process.env.VUE_APP_API_URL}campaigns?${params.toString()}`)
            .then(({data}) => {
                const datas = data.data
                datas.forEach(value => {
                    const percenWakaf =  value.summary  ? (value.summary.total_terhimpun / value.target) * 100 : 0
                    const valueTarget = value.target_date ? this.$moment(value.target_date)
                    .startOf('day')
                    .fromNow() : ''

                    const objData = {
                        company: {
                         companyName: value.company ? value.company.company_name : null
                        },
                        wakafId: value.wakaf_id,
                        wakafName: value.wakaf_name,
                        totalTerhimpun: value.summary ? parseInt(value.summary.total_terhimpun)+0 : 0,
                        wakafTargetValue: valueTarget,
                        wakafUrl: value.wakaf_url,
                        wakafImage: imageCdn(value.wakaf_image_cover_url, 'campaign-list'),
                        wakafTargetPercent: parseInt(percenWakaf)
                    }

                    this.campaigns.push(objData)

                })
            })
            .catch(() => {
                // swal.fire({
                //   icon: 'error',
                //   title: 'Oops...',
                //   text: 'Terjadi kesalahan'
                // })
            })
        }
    }
}
</script>