<template>
  <h3 class="title__wrapper">Mau Sedekah Apa Hari Ini ?</h3>
  <LoaderCategory v-if="loading" :count="2" />
  <router-link v-else v-for="(category,index) in categories" :key="index" :to="`/category/${category.categoryUrl}`" class="card card__quran col-3">
    <div class="card-body card__body__category">
      <div class="card__icon__quran">
        <img :src="category.categoryImage" alt="Image Category" class="icon__category">
      </div>
      <div class="card__main">
        <h5 class="card__title">
          {{ category.categoryName }}
        </h5>
        <!-- <p class="card__desc">
          {{ category.categoryShortDesc }}
        </p> -->
      </div>
      
    </div>
  </router-link>
</template>

<script>
import LoaderCategory from '../Loader/LoaderCategory.vue'
import swal from 'sweetalert2'
export default {
  components: { LoaderCategory },
  data() {
    return {
      categories: [],
      loading: true
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.loading = true
      this.$store.dispatch('campaignCategories/fetchCategories').then(response => {
        this.categories = []
        response.forEach(value => {
          this.categories.push({
            categoryUrl: value.cat_url,
            categoryId: value.cat_id,
            categoryImage: value.cat_image_cover_url,
            categoryName: value.cat_name,
            categoryShortDesc: value.cat_short_description,
            categoryLongDesc: value.cat_long_description,
            categoryPosition: value.cat_position 
          })
        })
        this.loading = false
      }).catch(error => {
        this.loading = false
        swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
      })
    }
  }
}
</script>
