<template>
  <div class="jumbotron jumbotron-fluid">
    <!-- <img :src="imgBanner" alt="Banner Lembaga"> -->
    <div class="banner__gradient" />
    <div class="container">
      <div class="input-group mb-3 search__banner">
        <span class="input-group-text icon__search" @click="handleSubmitSearch" />
        <input
          type="text"
          class="form-control form__search"
          placeholder="Cari yang ingin kamu bantu"
          ref="searchCampaign"
          @keyup.enter="handleSubmitSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgBanner: require('../../assets/banner_lembaga.png')
    }
  },
  methods: {
    handleSubmitSearch() {
      const searchData = this.$refs.searchCampaign.value
      this.$router.push({
        path: '/search',
        query: { keyword: searchData }
      })
    }
  },
}
</script>
