<template>
    <div class="container register__lembaga-wrapper">
         <!--<h3 class="title__register-lembaga">Mau ngajuin program kebaikan?</h3> -->
         <!--<button type="button" class="btn btn-primary mt-2" @click="$router.push('/register-lembaga')">Ajukan Program Kebaikan Sekarang</button> -->
        <img :src="imgBanner" alt="Banner Register Lembaga" class="image-lembaga_component">
        <button type="button" class="btn btn-primary btn-register-lembaga mt-2" @click="$router.push('/register-lembaga')">Ajukan Program Kebaikan Sekarang</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgBanner: require('../../assets/banner_lembaga.png')
        }
    }
}
</script>