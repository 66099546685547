<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img :src="logoImage" alt="Logo" class="icon-brand"/></a>
      <a class="navbar-brand" href="#" hidden><img :src="logoImage2" alt="Logo" class="icon-brand"/></a>
      <button class="navbar-toggler" type="button" v-on:click="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="nav__menu" id="nav__menu">
        <div class="nav__close d-none" id="nav__close">
          <button type="button" class="btn-close" id="btn-close" aria-label="Close" @click="closeMenu"></button>
        </div>

        <div class="menu__link">
          <ul>
            <li>
              <router-link to="/about" class="link__item">
                <div class="link__icon">
                  <i class="fas fa-info-circle" />
                </div>
                <span>Tentang Kami</span>
              </router-link>
            </li>
            <li>
              <router-link to="/term-condition" class="link__item">
                <div class="link__icon">
                   <i class="fas fa-file-alt" />
                </div>
                <span>Syarat & Ketentuan</span>
              </router-link>
            </li>
            <li>
              <router-link to="/privacy" class="link__item">
                <div class="link__icon">
                  <i class="fas fa-shield-alt" />
                </div>
                <span>Kebijakan Privasi</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      navLinks: [{ to: '/', label: 'Home' }],
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      logoImage: require('../../assets/amazing.png'),
      logoImage2: require('../../assets/img/logo-amazing-width.jpeg')
    }
  },
  methods: {
    toggleMenu() {
      const navMenu = document.getElementById('nav__menu')
      const navClose = document.getElementById('nav__close')
      navMenu.classList.toggle('show')
      navClose.classList.remove('d-none')
    },
    closeMenu() {
      const navMenu = document.getElementById('nav__menu')
      const navClose = document.getElementById('nav__close')
      navMenu.classList.remove('show')
      navClose.classList.toggle('d-none')
    }
  }
}
</script>
