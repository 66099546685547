<template>
  <div class="navbar__bottom">
    <ul class="navbar__menu">
      <li class="navbar__item">
        <router-link to="/" class="navbar__link">
          <div class="navbar__icon-wrapper">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#828282"
              class="icon-menu"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8185 7.38796L9.30631 0.832861C9.29796 0.824424 9.28931 0.816236 9.28049 0.808299C8.5694 0.16983 7.49487 0.167549 6.78106 0.803018C6.77218 0.810924 6.76356 0.81908 6.75515 0.827455L0.183618 7.38605C-0.060694 7.62992 -0.0611003 8.02564 0.182743 8.26996C0.426618 8.51427 0.822337 8.51464 1.06662 8.27083L1.56262 7.7758V13.1722C1.56262 14.5507 2.68412 15.6722 4.06262 15.6722H6.31262C6.65781 15.6722 6.93762 15.3924 6.93762 15.0472V9.95342H9.12512V15.0472C9.12512 15.3924 9.40493 15.6722 9.75012 15.6722H11.9376C13.3161 15.6722 14.4376 14.5507 14.4376 13.1722C14.4376 12.827 14.1578 12.5472 13.8126 12.5472C13.4674 12.5472 13.1876 12.827 13.1876 13.1722C13.1876 13.8614 12.6269 14.4222 11.9376 14.4222H10.3751V9.32842C10.3751 8.98324 10.0953 8.70342 9.75012 8.70342H6.31262C5.96743 8.70342 5.68762 8.98324 5.68762 9.32842V14.4222H4.06262C3.37337 14.4222 2.81262 13.8614 2.81262 13.1722V6.54717C2.81262 6.54092 2.81252 6.53471 2.81234 6.52852L7.62343 1.72692C7.85759 1.52808 8.20103 1.52883 8.43427 1.72864L13.1876 6.51333V10.0472C13.1876 10.3924 13.4674 10.6722 13.8126 10.6722C14.1578 10.6722 14.4376 10.3924 14.4376 10.0472V7.77155L14.9317 8.26896C15.0539 8.39192 15.2145 8.45345 15.3751 8.45345C15.5344 8.45345 15.6937 8.39292 15.8156 8.27183C16.0605 8.02855 16.0618 7.63283 15.8185 7.38796Z"
                fill="#828282"
              />
            </svg>
          </div>
          <span class="nav__title">Beranda</span>
        </router-link>
      </li>
      <li class="navbar__item">
        <router-link :to="{ path: '/donasi-saya' }" class="navbar__link">
          <div class="navbar__icon-wrapper">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#828282"
              class="icon-menu"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6569 2.34313C12.1458 0.832125 10.1369 0 8 0C5.86312 0 3.85416 0.832125 2.34316 2.34313C0.832156 3.85413 0 5.86312 0 8C0 10.1369 0.832156 12.1458 2.34316 13.6568C3.85416 15.1678 5.86312 16 8 16C9.46281 16 10.8942 15.6013 12.1394 14.8471C12.4347 14.6683 12.529 14.284 12.3502 13.9887C12.1713 13.6935 11.7869 13.5991 11.4918 13.7779C10.4419 14.4139 9.23444 14.75 8 14.75C4.27803 14.75 1.25 11.722 1.25 8C1.25 4.27803 4.27803 1.25 8 1.25C11.722 1.25 14.75 4.27803 14.75 8C14.75 9.33088 14.3542 10.6264 13.6054 11.7464C13.4136 12.0333 13.4907 12.4215 13.7777 12.6133C14.0647 12.8052 14.4527 12.7281 14.6446 12.4411C15.5313 11.1147 16 9.57906 16 8C16 5.86312 15.1678 3.85413 13.6569 2.34313Z"
                fill="#828282"
              />
            </svg>
          </div>
          <span class="nav__title">Donasi</span>
        </router-link>
      </li>
      <li class="navbar__item">
        <router-link to="#" class="navbar__link disabled-link">
          <div class="navbar__icon-wrapper">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="#828282"
              class="icon-menu"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.375 8.10938C15.7202 8.10938 16 7.82956 16 7.48438V3.39062C16 2.01213 14.8785 0.890625 13.5 0.890625H2.5C1.1215 0.890625 0 2.01213 0 3.39062V10.6094C0 11.9879 1.1215 13.1094 2.5 13.1094H13.5C14.8785 13.1094 16 11.9879 16 10.6094C16 10.2642 15.7202 9.98438 15.375 9.98438C15.0298 9.98438 14.75 10.2642 14.75 10.6094C14.75 11.2986 14.1892 11.8594 13.5 11.8594H2.5C1.81075 11.8594 1.25 11.2986 1.25 10.6094V3.52306L6.67984 6.89947C7.087 7.15263 7.5435 7.27922 8 7.27922C8.4565 7.27922 8.913 7.15263 9.32016 6.89947L14.75 3.52306V7.48438C14.75 7.82956 15.0298 8.10938 15.375 8.10938ZM8.66006 5.83794C8.25294 6.09109 7.74706 6.09113 7.33994 5.83794L1.77153 2.37538C1.97678 2.22769 2.22841 2.14062 2.5 2.14062H13.5C13.7716 2.14062 14.0232 2.22772 14.2285 2.37541L8.66006 5.83794Z"
                fill="#828282"
              />
            </svg>
          </div>
          <span class="nav__title">Notifikasi</span>
        </router-link>
      </li>
      <li class="navbar__item">
        <router-link :to="{ path: '/login' }" class="navbar__link" v-if="accessToken === null">
          <div class="navbar__icon-wrapper">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#828282"
              class="icon-menu"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7308 10.1566C14.2006 9.69067 13.5575 9.28809 12.8193 8.96008C12.5038 8.81995 12.1345 8.96204 11.9944 9.27747C11.8542 9.5929 11.9963 9.96216 12.3118 10.1024C12.9342 10.379 13.4706 10.7131 13.9058 11.0956C14.4423 11.567 14.75 12.2496 14.75 12.9688V14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.5304 14.75 1.25 14.4696 1.25 14.125V12.9688C1.25 12.2496 1.55774 11.567 2.09424 11.0956C2.72571 10.5406 4.56555 9.25 8 9.25C10.5502 9.25 12.625 7.17517 12.625 4.625C12.625 2.07483 10.5502 0 8 0C5.44983 0 3.375 2.07483 3.375 4.625C3.375 6.11584 4.08423 7.44397 5.18286 8.29028C3.17261 8.73218 1.92212 9.58276 1.26917 10.1566C0.462646 10.8652 0 11.8901 0 12.9688V14.125C0 15.1589 0.841064 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V12.9688C16 11.8901 15.5374 10.8652 14.7308 10.1566ZM4.625 4.625C4.625 2.76404 6.13904 1.25 8 1.25C9.86096 1.25 11.375 2.76404 11.375 4.625C11.375 6.48596 9.86096 8 8 8C6.13904 8 4.625 6.48596 4.625 4.625Z"
                fill="#828282"
              />
            </svg>
          </div>
          <span class="nav__title">Login</span>
        </router-link>
        <router-link class="navbar__link" v-else :to="`/my-account/${accountData.name}`">
          <div class="navbar__icon-wrapper">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#828282"
              class="icon-menu"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7308 10.1566C14.2006 9.69067 13.5575 9.28809 12.8193 8.96008C12.5038 8.81995 12.1345 8.96204 11.9944 9.27747C11.8542 9.5929 11.9963 9.96216 12.3118 10.1024C12.9342 10.379 13.4706 10.7131 13.9058 11.0956C14.4423 11.567 14.75 12.2496 14.75 12.9688V14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.5304 14.75 1.25 14.4696 1.25 14.125V12.9688C1.25 12.2496 1.55774 11.567 2.09424 11.0956C2.72571 10.5406 4.56555 9.25 8 9.25C10.5502 9.25 12.625 7.17517 12.625 4.625C12.625 2.07483 10.5502 0 8 0C5.44983 0 3.375 2.07483 3.375 4.625C3.375 6.11584 4.08423 7.44397 5.18286 8.29028C3.17261 8.73218 1.92212 9.58276 1.26917 10.1566C0.462646 10.8652 0 11.8901 0 12.9688V14.125C0 15.1589 0.841064 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V12.9688C16 11.8901 15.5374 10.8652 14.7308 10.1566ZM4.625 4.625C4.625 2.76404 6.13904 1.25 8 1.25C9.86096 1.25 11.375 2.76404 11.375 4.625C11.375 6.48596 9.86096 8 8 8C6.13904 8 4.625 6.48596 4.625 4.625Z"
                fill="#828282"
              />
            </svg>
          </div>
          <span class="nav__title">Akun Saya</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import swal from 'sweetalert2'
export default {
  data() {
    return {
      accessToken: null,
      accountData: {
        name: null
      }
    }
  },
  mounted() {
    this.setAccessToken()
  },
  methods: {
    setAccessToken() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
          const accountData = JSON.parse(localStorage.getItem('account_data'))
          this.accountData.name = accountData.detail.display_name
        } else {
          this.accessToken = null
        }
      }
    },
    handleLogout() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$store
        .dispatch('account/logout')
        .then(() => {
          // this.$router.push('/')
          const GoogleAuth = window.gapi.auth2.getAuthInstance()
          if (GoogleAuth.isSignedIn.get()) {
            GoogleAuth.signOut()
          }
          location.reload()
          swal.close()
          swal.hideLoading()
        })
        .catch(() => {
          location.reload()
          // this.$router.push('/')
          swal.close()
          swal.hideLoading()
        })
    }
  }
}
</script>
