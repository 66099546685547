<template>
  <div class="container program__choice-wrapper">
    <h3 class="title__wrapper">Program Mendesak Dibantu</h3>

    <div class="container card__wrapper d-flex aligns-items-center">
      <swiper
        class="swiper"
        :slidesPerView="2"
        :spaceBetween="6"
        :slidesPerGroup="2"
        :navigation="true"
      >
        <swiper-slide
          v-for="(campaign, index) in campaigns"
          :key="index"
          class="swiper-slide"
          style="width:210px;"
        >
          <router-link :to="`/${campaign.wakafUrl}`" class="card card__program-choice">
            <figure class="image__program-gallery">
              <img :src="campaign.wakafImage" alt="" class="image__program" />
            </figure>
            <div class="card-body card__body-wrapper">
              <div class="title__program-wrapper">
                <span class="card-title title__card">
                  {{ campaign.wakafName }}
                </span>
              </div>
              <div class="lembaga__program-wrapper">
                <div class="lembaga__title">
                  <span>{{ campaign.company ? campaign.company.companyName : '-' }}</span>
                </div>
              </div>
              <div class="progress__program-wrapper">
                <div class="progress progress__category progress__program">
                  <div
                    class="progress-bar progress__bar-program"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: `${campaign.wakafTargetPercent}%` }"
                  />
                </div>
              </div>
              <div class="donation__collected-wrapper">
                <span>Terkumpul</span>
                <span>Rp. {{ $formatPrice(campaign.totalTerhimpun) }}</span>
              </div>
              <div class="end__donation-wrapper" v-if="campaign.wakafStatus === 'published'">
                <span class="value__end-date">
                  <b>{{ campaign.wakafTargetValue }}</b>
                </span>
              </div>
              <div class="end__donation-wrapper" v-if="campaign.wakafStatus === 'fullfunded'">
                <span class="value__end-date">
                  <b>Selesai</b>
                </span>
              </div>
            </div>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { imageCdn } from '@/_helpers'
import swal from 'sweetalert2'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imageOne: require('../../assets/img/image_popular_1.jpeg'),
      imageTwo: require('../../assets/img/image_popular_2.jpeg'),
      imageThree: require('../../assets/img/image_popular_3.jpeg'),
      campaigns: []
    }
  },
  mounted() {
    this.getProgramsChoice()
  },
  methods: {
    getProgramsChoice() {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)
      params.append('order', 'published_at')
      params.append('descanding', 1)
      params.append('limit', 12)
      params.append('ignore_description', 1)
      params.append('need_campaign', 1)

      this.$http
        .get(`${process.env.VUE_APP_API_URL}selected-campaigns?${params.toString()}`)
        .then(response => {
          const datas = response.data.data
          for (const index in datas) {
            const percenWakaf = datas[index].campaign.summary
              ? (datas[index].campaign.summary.total_terhimpun / datas[index].campaign.target) * 100
              : 0

            const valueTarget =
              datas[index].campaign.target_date !== null
                ? this.$moment(datas[index].campaign.target_date)
                    .startOf('day')
                    .fromNow()
                : ''

            const objData = {
              company: {
                companyName: datas[index].company ? datas[index].company.company_name : null
              },
              wakafId: datas[index].campaign.wakaf_id,
              wakafName: datas[index].campaign.wakaf_name,
              totalTerhimpun: datas[index].campaign.summary
                ? parseInt(datas[index].campaign.summary.total_terhimpun) + 0
                : 0,
              wakafTargetValue: valueTarget,
              wakafStatus: datas[index].campaign.wakaf_status,
              wakafUrl: datas[index].campaign.wakaf_url,
              wakafImage: imageCdn(datas[index].campaign.wakaf_image_cover_url, 'campaign-list'),
              wakafTargetPercent: parseInt(percenWakaf)
            }

            this.campaigns.push(objData)
          }
        })
        .catch(error => {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
        })
    }
  }
}
</script>
