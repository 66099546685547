<template>
  <div class="card card__loader-category" v-for="n in count" :key="n">
    <Skeleton width="62px" height="64px" class="card__icon__loader-category"/>
    <div class="card-body card__body__loader-category">
      <div class="card__main__loader-category">
        <Skeleton width="50px" height="22" />
        <Skeleton width="100px" height="18" style="margin-top:5px;" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
  components: { Skeleton },
  props: {
    count: { type: Number, default: 1 }
  }
}
</script>

<style lang="scss" scopoed>
.card__loader-category {
  height: 135px !important;
  border: 0.5px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  margin-right:0.5rem;
}

.card__body__loader-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem !important;
}
.card__icon__loader-category {
  border-radius: 6px !important;
  margin-left:10px;
  margin-top:1px;
}
</style>
